<template>
    <div class="w-full">
        <SummaryBar :historyData="historyData" />

        <div v-loading="$wait.is('loading')" class="card" element-loading-spinner="el-custom-spinner">
            <div class="flex justify-end">
                <div class="menu flex">
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.export_to_pdf')" placement="top">
                        <el-button type="primary" size="mini" :disabled="historyData.length == 0" @click="toPdf(false)">
                            <fa-icon :icon="['fas', 'file-pdf']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.print')" placement="top">
                        <el-button type="primary" size="mini" :disabled="historyData.length == 0" class="btn-print" @click="toPdf(true)">
                            <fa-icon :icon="['fas', 'print']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <DateRangePicker @change="getAll" />
                </div>
            </div>
            <hr class="my-4">
            <el-table v-if="historyData.length > 0" :data="historyData" class="w-full">
                <el-table-column prop="status" width="25">
                    <template slot-scope="scope">
                        <el-tooltip
                            v-if="workingStatus(scope.row)"
                            :enterable="false"
                            :openDelay="500"
                            effect="dark"
                            :content="$t('employees.working')"
                            placement="right"
                        >
                            <span class="w-3 h-3 bg-green-500 flex rounded-full" />
                        </el-tooltip>
                        <el-tooltip
                            v-if="shouldFinishStatus(scope.row)"
                            :enterable="false"
                            :openDelay="500"
                            effect="dark"
                            :content="$t('employees.should_finish_already_20_min')"
                            placement="right"
                        >
                            <span class="w-3 h-3 bg-orange-400 flex rounded-full" />
                        </el-tooltip>
                        <el-tooltip
                            v-if="scope.row.status === 2"
                            :enterable="false"
                            :openDelay="500"
                            effect="dark"
                            :content="$t('employees.didnt_checked_out')"
                            placement="right"
                        >
                            <span class="w-3 h-3 bg-red-500 flex rounded-full" />
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('employees.client')" prop="client" />
                <el-table-column :label="$t('employees.address')" prop="address" :show-overflow-tooltip="true" />
                <el-table-column :label="$t('employees.duration')" prop="duration" width="100">
                    <template slot-scope="scope">
                        {{ normalizeTime(scope.row.duration) }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('employees.planned')" prop="planned" width="100">
                    <template slot-scope="scope">
                        {{ normalizeTime(scope.row.planned) }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('employees.rate')" width="170">
                    <template slot-scope="scope">
                        <div class="flex items-center">
                            <el-rate :value="scope.row.rate ? scope.row.rate.rate : 0" disabled />
                            <el-popover
                                v-if="scope.row.rate && scope.row.rate.comment"
                                placement="top-end"
                                :title="$t('employees.comment')"
                                width="300"
                                trigger="hover"
                                :content="scope.row.rate.comment"
                            >
                                <span slot="reference" class="flex h-5 w-5 justify-center items-center rounded-full border border-gray-600">
                                    <fa-icon :icon="['fas', 'info']" />
                                </span>
                            </el-popover>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('employees.Date')" prop="date" width="120" />
            </el-table>

            <NoDataInformation :data="noDataInformation" waitKey="loading" />
        </div>
    </div>
</template>
<script>
import sumBy from 'lodash/sumBy';
import meanBy from 'lodash/meanBy';
import queryString from 'query-string';

export default {
    components: {
        DateRangePicker: () => import(/* webpackChunkName: "DateRangePicker" */ '@/components/DateRangePicker'),
        SummaryBar:      () => import(/* webpackChunkName: "EmployeeHistory/SummaryBar" */ './components/SummaryBar'),
    },

    data() {
        return {
            noDataInformation: false,
            employeeUuid:      this.$route.params.uuid,
            historyData:       [],
        };
    },

    computed: {
        totalHours() {
            return this.normalizeTime(sumBy(this.historyData, 'duration'));
        },

        totalPlannedHours() {
            return this.normalizeTime(sumBy(this.historyData, 'planned'));
        },

        averageRating() {
            const realRatings = this.historyData.filter(object => object.rate && object.rate.rate > 0);
            const average = meanBy(realRatings, 'rate.rate').toFixed(1);

            return realRatings.length > 0 ? average : 0;
        },
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'history');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading');
            const query = queryString.parse(window.location.search, { sort: false });

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/service_history`, {
                params: {
                    employee: this.employeeUuid,
                    from:     query.from ? query.from : this.$dayjs().startOf('month').unix(),
                    to:       query.to ? query.to : this.$dayjs().endOf('month').unix(),
                },
            });

            this.historyData = data;
            this.noDataInformation = data.length;
            this.$wait.end('loading');
            this.$wait.end('loading_date_range.prev');
            this.$wait.end('loading_date_range.next');
        },

        async toPdf(toPrint = false) {
            const query = queryString.parse(window.location.search, { sort: false });

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/service_history/service_history_pdf_sign_route`, {
                params: {
                    employee: this.employeeUuid,
                    from:     query.from ? query.from : this.$dayjs().startOf('month').unix(),
                    to:       query.to ? query.to : this.$dayjs().endOf('month').unix(),
                    print:    toPrint,
                },
            });

            window.open(data);
        },

        shouldFinishStatus(row) {
            const shouldFinishAt = this.$dayjs(row.date, 'HH:mm DD.MM.YYYY').add(row.planned + 20, 'minute');
            if (row.status === 0 && this.$dayjs() > shouldFinishAt) {
                return true;
            }
            return false;
        },

        workingStatus(row) {
            const shouldFinishAt = this.$dayjs(row.date, 'HH:mm DD.MM.YYYY').add(row.planned + 20, 'minute');
            if (row.status === 0 && this.$dayjs() < shouldFinishAt) {
                return true;
            }
            return false;
        },
    },
};
</script>
<style scoped>
.btn-print {
    margin-right: 10px !important;
}

.menu .el-button--mini {
    padding: 7px !important;
}
</style>
